import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>
                        Et presentem la 10a edició del curs teòric sobre la Llei de Seguretat
                        Ciutadana i el Codi Penal.
                    </strong>
                </p>

                <p>
                    El pròxim <strong>6 d'abril</strong>, impartirem un curs molt complet i
                    imprescindible per aprofundir en aquestes lleis i la seva aplicació.
                </p>

                <p>
                    <strong>Enfocat especialment a opositors</strong>, aquest curs et proporcionarà
                    tots els conceptes, definicions i coneixements necessaris per a treballar amb
                    seguretat.
                </p>

                <h2>
                    <span role="img" aria-label="contingut">
                        📚
                    </span>{" "}
                    Contingut del curs
                </h2>
                <ul>
                    <li>
                        <strong>Introducció a la Llei orgànica 4/2015</strong>
                    </li>
                    <li>Estructura i contingut</li>
                    <li>Infraccions i règim sancionador</li>
                    <li>Diferència entre delictes lleus, menys greus i greus</li>
                    <li>Quan procedeix un trasllat a l'efecte d'identificació i quan no</li>
                    <li>La identificació</li>
                    <li>La detenció</li>
                    <li>Judicis ràpids</li>
                    <li>Habeas corpus</li>
                    <li>Tipus de registres superficials</li>
                    <li>El concepte de domicili</li>
                    <li>L'ocupació</li>
                    <li>Infraccions amb armes i drogues</li>
                    <li>
                        La desobediència als agents: diferència entre infracció administrativa i
                        penal
                    </li>
                    <li>Les últimes modificacions en delictes sexuals i molt més</li>
                </ul>

                <h2>
                    <span role="img" aria-label="ubicació">
                        📍
                    </span>{" "}
                    Ubicació i horari
                </h2>
                <p>El curs es realitzarà a:</p>
                <p>
                    <strong>
                        <span role="img" aria-labelledby="direcció">
                            📌
                        </span>{" "}
                        c/ Martí Codolar núm. 18, L'Hospitalet de Llobregat
                    </strong>
                </p>
                <p>
                    <span role="img" aria-labelledby="horari">
                        🕘
                    </span>{" "}
                    <strong>Horari:</strong> De 9.00 a 14.00 i de 15.00 a 18.00 h
                </p>

                <h2>
                    <span role="img" aria-labelledby="material">
                        📖
                    </span>{" "}
                    Material del curs
                </h2>
                <p>
                    A tots els assistents se us proporcionarà un <strong>dossier explicatiu</strong>
                    .
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Domina la Llei de Seguretat Ciutadana i el Codi Penal amb un curs pràctic i essencial per a opositors. Aprèn a aplicar la normativa amb seguretat i confiança. Reserva la teva plaça ara!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
